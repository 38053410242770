<template>
  <!-- 项目概况监管 -->
  <div class="container">
    <!-- 第一个卡片 -->
    <el-card v-if="current == 1">
      <!-- 搜索区域 -->
      <div class="search">
        <el-row>
          <el-form ref="searchForm" :model="searchForm" label-width="80px">
            <el-col :span="4">
              <el-form-item label="项目名称 : " prop="name">
                <el-input v-model="searchForm.name"></el-input>
              </el-form-item>
            </el-col>


            <!-- <el-col :span="4">
              <el-form-item label="区域位置">
                <el-input v-model="searchForm.screen"></el-input>
              </el-form-item>
            </el-col> -->
            <el-col :span="4">
              <el-button type="primary" @click="searchProject" class="searhAllBtn">查询</el-button>
              <el-button type="primary" @click="resetProject_Name">重置</el-button>
            </el-col>
          </el-form>
        </el-row>
      </div>
      <!-- 表格区域 -->
      <div class="main">
        <el-table :data="tableData1" style="width: 100%" :header-cell-style="headerCellStyle" :cell-style="cellStyle"
          :border="false">
          <el-table-column prop="name" label="项目名称" width="250" align="center">
          </el-table-column>

          <el-table-column prop="engineering" label="工程类型" align="center">
          </el-table-column>
          <el-table-column prop="ctime" label="开工日期" align="center">
          </el-table-column>
          <el-table-column prop="utime" label="拟竣工日期" align="center">
          </el-table-column>

          <el-table-column prop="quality_proof" label="资质证明" align="center">
          </el-table-column>
          <el-table-column prop="Complete_certify" label="完工证明" align="center">
          </el-table-column>

          <el-table-column label="操作" width="200">
            <template slot-scope="scope">
              <a @click="getPorjectRisk(scope.row)" style="padding-right: 10px">查看详情</a>
              <a @click="evaluationHandle(scope.row)" style="color: #39bd65">上传图片</a>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div class="footer">
        <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="page"
          :page-sizes="[10, 20, 50]" :page-size="pageSize" layout="total, sizes, prev, pager, next, jumper"
          :total="total">
        </el-pagination>
      </div>
      <el-dialog title="上传图片" :visible.sync="scoreDialogVisible" width="40%" @close="scoreHandleClose">
        <el-form ref="scoreFormData" :model="scoreFormData" label-position="left" label-width="220px">
          <el-form-item label="施工许可证" prop="builder_license">
            <el-upload class="upload-demo" action="http://139.9.0.126:8088/api/doc/" :on-success="successHandle1"
              :before-remove="beforeRemove" multiple ref="upload1" :limit="1" :headers="token" :on-exceed="handleExceed"
              :file-list="fileList" accept=".jpg,.png">
              <el-button size="small" type="primary">获取文件</el-button>
              <div slot="tip" class="el-upload__tip">
                只能上传jpg/png文件，且不超过2M
              </div>
            </el-upload>
          </el-form-item>
          <el-form-item label="施工总承包单位安全生产许可证" prop="safety_license">
            <el-upload class="upload-demo" action="http://139.9.0.126:8088/api/doc/" :on-success="successHandle2"
              :before-remove="beforeRemove" multiple ref="upload2" :limit="1" :headers="token" :on-exceed="handleExceed"
              :file-list="fileList" accept=".jpg,.png">
              <el-button size="small" type="primary">获取文件</el-button>
              <div slot="tip" class="el-upload__tip">
                只能上传jpg/png文件，且不超过2M
              </div>
            </el-upload>
          </el-form-item>
          <el-form-item label="企业资质等级复印件" prop="enterprise_level">
            <el-upload class="upload-demo" action="http://139.9.0.126:8088/api/doc/" :on-success="successHandle3"
              :before-remove="beforeRemove" multiple ref="upload3" :limit="1" :headers="token" :on-exceed="handleExceed"
              :file-list="fileList" accept=".jpg,.png">
              <el-button size="small" type="primary">获取文件</el-button>
              <div slot="tip" class="el-upload__tip">
                只能上传jpg/png文件，且不超过2M
              </div>
            </el-upload>
          </el-form-item>
          <el-form-item label="荣誉证书复印件" prop="honor_license">
            <el-upload class="upload-demo" action="http://139.9.0.126:8088/api/doc/" :on-success="successHandle4"
              :before-remove="beforeRemove" multiple ref="upload4" :limit="1" :headers="token" :on-exceed="handleExceed"
              :file-list="fileList" accept=".jpg,.png">
              <el-button size="small" type="primary">获取文件</el-button>
              <div slot="tip" class="el-upload__tip">
                只能上传jpg/png文件，且不超过2M
              </div>
            </el-upload>
          </el-form-item>
          <el-form-item label="完工证明" prop="Complete_certify">
            <el-upload class="upload-demo" action="http://139.9.0.126:8088/api/doc/" :on-success="successHandle5"
              :before-remove="beforeRemove" multiple ref="upload5" :limit="5" :headers="token" :on-exceed="handleExceed"
              :file-list="fileList" accept=".jpg,.png">
              <el-button size="small" type="primary">获取文件</el-button>
              <div slot="tip" class="el-upload__tip">
                只能上传jpg/png文件，且不超过2M
              </div>
            </el-upload>
          </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
          <el-button @click="scoreDialogVisible = false">取 消</el-button>
          <el-button type="primary" @click="scoreHandle">上传图片</el-button>
        </span>
      </el-dialog>

      <!-- <div class="out"></div> -->
    </el-card>
    <!-- 第二个卡片 -->
    <el-card v-else-if="current == 2">
      <div class="projectInfo">
        <div class="projectTitle">
          <div>项目基本信息</div>

          <el-button type="primary" @click="goProject">返回上级</el-button>
        </div>
        <div>
          项目名称: <span> {{ projectInfo.name }}</span>
        </div>

        <div>
          工程类型: <span>{{ projectInfo.engineering }}</span>
        </div>
        <div>
          当前阶段: <span> {{ projectInfo.angineering }}</span>
        </div>
        <div>
          开工日期: <span>{{ projectInfo.create_time }}</span>
        </div>
        <div>
          拟竣工日期: <span>{{ projectInfo.update_time }}</span>
        </div>
      </div>
      <div class="situation">
        <div class="situationTitle">项目概况</div>
        <div class="situationBody">
          <div class="prove">资质证明</div>
          <div class="imgShow">
            <div class="imgList">
              <div class="img_top">


                <div v-if="scoreFormData.builder_license.length">

                  <el-image lazy v-for="(item, index) in scoreFormData.builder_license" :key="index"
                    style="width: 100px; height: 100px" :src="item ? item : ''"
                    :preview-src-list="scoreFormData.builder_license"></el-image>
                </div>
                <div v-else>
                  <el-image src="http://139.9.0.126:9999/未上传图片.png"></el-image>
                </div>
              </div>
              <div class="img_bottom">施工许可证</div>
            </div>
            <div class="imgList">
              <div class="img_top">
                <div v-if="scoreFormData.safety_license.length">
                  <el-image lazy v-for="(item, index) in scoreFormData.safety_license" :key="index"
                    style="width: 100px; height: 100px" :src="item ? item : ''"
                    :preview-src-list="scoreFormData.safety_license"></el-image>
                </div>
                <div v-else>
                  <el-image src="http://139.9.0.126:9999/未上传图片.png"></el-image>
                </div>
              </div>
              <div class="img_bottom">施工总承包单位安全生产许可证</div>
            </div>
            <div class="imgList">
              <div class="img_top">
                <div v-if="scoreFormData.enterprise_level.length">
                  <el-image lazy v-for="(item, index) in scoreFormData.enterprise_level" :key="index"
                    style="width: 100px; height: 100px" :src="item ? item : ''"
                    :preview-src-list="scoreFormData.enterprise_level"></el-image>
                </div>
                <div v-else>
                  <el-image src="http://139.9.0.126:9999/未上传图片.png"></el-image>
                </div>
              </div>
              <div class="img_bottom">企业资质等级复印件</div>
            </div>
            <div class="imgList">
              <div class="img_top">
                <div v-if="scoreFormData.honor_license.length">
                  <el-image lazy v-for="(item, index) in scoreFormData.honor_license" :key="index"
                    style="width: 100px; height: 100px" :src="item ? item : ''"
                    :preview-src-list="scoreFormData.honor_license"></el-image>
                </div>
                <div v-else>
                  <el-image src="http://139.9.0.126:9999/未上传图片.png"></el-image>
                </div>
              </div>
              <div class="img_bottom">荣誉证书复印件</div>
            </div>
          </div>
          <div class="prove">完工证明</div>
          <div class="completion">
            <el-image lazy v-for="(item, index) in scoreFormData.Complete_certify" :key="index"
              style="width: 100px; height: 100px" :src="item ? item : ''"
              :preview-src-list="scoreFormData.Complete_certify"></el-image>
          </div>
        </div>
      </div>

      <el-dialog :visible.sync="showDialogVisible" width="50%">
        <div>
          <el-image style="width: 900px; height: 500px" :src="url"></el-image>
        </div>

        <!-- <div>部位:{{pictureInfo.construction_request}}</div>
         <div>相关要求:{{pictureInfo.requirement}}</div> -->
        <span slot="footer" class="dialog-footer">
          <el-button @click="showDialogVisible = false">取 消</el-button>
        </span>
      </el-dialog>
    </el-card>
  </div>
</template>

<script>
import {
  getProjectRegulatory,
  addRegulatoryPicture,
  getRegulatoryPicture,
} from "../../../request/production";
import { getProjectInfo } from "../../../request/project";
export default {
  name: "projectRegulatory",
  data() {
    return {
      project_Name: "", //第一个卡片 搜索项目名称
      //   第一个卡片表格数据
      tableData1: [],
      // 第二个卡片表格数据

      total: 10, // 表格分页总数
      page: 1,
      pageSize: 10,

      current: 1, // 第几个卡片
      flag: true, //判断用户权限 系统管理员  政府人员 true    项目管理员  普通用户  false
      activeName: "1", //标签页

      projectId: "", //点击项目详情 记录id
      projectName: "", //项目名称
      companyName: "", //公司名称
      riskInfo: {}, // 第三个卡片数据
      riskNum: {}, //所有项目风险数量
      projectRiskNum: {}, // 选中项目风险数量

      searchForm: {
        name: "",
        types: "",
      }, // 搜索表单
      projectInfo: {}, //指定项目信息

      screeningTableData: [], //排查要求表格
      showDialogVisible: false, //控制图片详情对话框的显示与隐藏
      pictureInfo: {}, //图片信息对话框
      projectOptions: [
        {
          label: "基础施工阶段",
          value: "基础施工阶段",
        },
        {
          label: "主体施工阶段",
          value: "主体施工阶段",
        },
        {
          label: "装饰装修阶段",
          value: "装饰装修阶段",
        },
      ], //项目阶段下拉框
      scoreDialogVisible: false, //项目评价对话框的显示与隐藏
      scoreFormData: {
        builder_license: [],
        safety_license: [],
        enterprise_level: [],
        honor_license: [],
        Complete_certify: [],
      }, //项目评分对话框表单
      token: {
        Authorization: localStorage.getItem("TOKEN"),
      },
      fileList: [], // 上传图片数组
      scoreTableData: [], //评价表格
      url: "", //查看图片详情url
      errobj: {},
      imgUrl: 'http://139.9.0.126:9999/未上传图片.png',
      pjtid: '',

    };
  },
  watch: {
    $route() {
      this.pjtid = this.$route.query.id
    },
    pjtid() {
      this.current = 1;
      this.getProjectRisk();
    },
  },
  created() {
    this.getProjectRisk();
  },
  methods: {

    // 根据权限 获取风险统计
    getProjectRisk() {
      this.flag =
        localStorage.getItem("rank") == 0
          ? true
          : false;
      console.log(this.flag);
      if (this.flag) {
        // 系统管理员  政府账号  显示第一个卡片  获取所有项目
        this.getProjectRiskStatistical();
      } else {
        // 项目管理员  普通用户  显示第二个卡片  获取所属项目所有风险
        // this.current = 2;
        this.projectId = localStorage.getItem("corporations");
        this.getProjectRiskStatistical();
      }
    },

    //  beforeAvatarUpload(file) {
    //     const isJPG = file.type === 'image/jpeg';
    //     const isLt2M = file.size / 1024 / 1024 < 2;

    //     if (!isJPG) {
    //       this.$message.error('上传头像图片只能是 JPG 格式!');
    //     }
    //     if (!isLt2M) {
    //       this.$message.error('上传头像图片大小不能超过 2MB!');
    //     }

    //   },
    successHandle1(res) {
      // console.log(res.res);

      this.scoreFormData.builder_license.push(res.res);
    },
    successHandle2(res) {
      // console.log(res.res);

      this.scoreFormData.safety_license.push(res.res);
    },
    successHandle3(res) {
      // console.log(res.res);

      this.scoreFormData.enterprise_level.push(res.res);
    },
    successHandle4(res) {
      // console.log(res.res);

      this.scoreFormData.honor_license.push(res.res);
    },
    successHandle5(res) {
      // console.log(res.res);

      this.scoreFormData.Complete_certify.push(res.res);
    },
    beforeRemove(file, fileList) {
      return this.$confirm(`确定移除 ${file.name}？`);
    },
    handleExceed(files, fileList) {
      this.$message.warning(
        `当前限制选择 1 个文件，本次选择了 ${files.length} 个文件，共选择了 ${files.length + fileList.length
        } 个文件`
      );
    },

    // 第一个卡片搜索
    searchProject() {
      this.page = 1
      this.getProjectRiskStatistical();
    },
    // 第一个卡片重置
    resetProject_Name() {
      this.searchForm.name = ''
      this.getProjectRiskStatistical();

    },
    // 获取所有项目风险统计
    async getProjectRiskStatistical() {
      let msg = {
        unique_identification: localStorage.getItem("uid"),
        page: this.page,
        size: this.pageSize,
        name: this.searchForm.name,
      };
      if (localStorage.getItem('isdealer') == 'true') {
        if (localStorage.getItem('type') == 'pro') {
          msg.pro_id = localStorage.getItem('project')
        } else if (localStorage.getItem('type') == 'firm') {
          msg.firm_id = localStorage.getItem('project')
        }
      }

      const res = await getProjectRegulatory(msg);

      this.tableData1 = res.data.msg;
      this.total = res.data.count;
      // if (!this.flag) {
      //   this.getPorjectRisk(res.data[0]);
      // }

      for (let i = 0; i < this.tableData1.length; i++) {
        this.tableData1[i].ctime = this.tableData1[i].ctime
          ? this.$moment(this.tableData1[i].ctime).format("YYYY-MM-DD HH:mm:ss")
          : this.tableData1[i].ctime;
        this.tableData1[i].utime = this.tableData1[i].utime
          ? this.$moment(this.tableData1[i].utime).format("YYYY-MM-DD HH:mm:ss")
          : this.tableData1[i].utime;
      }
      // 获取项目信息
    },
    // 项目上传图片
    evaluationHandle(row) {
      this.scoreDialogVisible = true;
      this.getPictureInfo(row);
    },
    //获取项目上传过的图片
    async getPictureInfo(row) {
      this.scoreFormData.pro_id = row.id;
      let msg = {
        pro_id: row.id,
      };

      const res = await getRegulatoryPicture(msg);
      // console.log(res.data.data);
      this.scoreFormData.builder_license.push(...res.data.data.builder_license);
      this.scoreFormData.safety_license.push(...res.data.data.safety_license);
      this.scoreFormData.enterprise_level.push(
        ...res.data.data.enterprise_level
      );
      this.scoreFormData.honor_license.push(...res.data.data.honor_license);
      this.scoreFormData.Complete_certify.push(
        ...res.data.data.Complete_certify
      );
      console.log(this.scoreFormData);
    },
    // 对项目上传图片
    async scoreHandle() {
      const res = await addRegulatoryPicture(this.scoreFormData);
      // console.log(res);
      if (res.data.code == 200) {
        this.scoreDialogVisible = false;
        this.getProjectRiskStatistical();
        this.$message.success("上传成功");
      }
    },
    // 上传图片对话框关闭
    scoreHandleClose() {
      this.$refs.scoreFormData.resetFields();
      // this.fileList.length = 0;
      this.$refs.upload1.clearFiles();
      this.$refs.upload2.clearFiles();
      this.$refs.upload3.clearFiles();
      this.$refs.upload4.clearFiles();
      this.$refs.upload5.clearFiles();
    },

    //   表格表头颜色
    headerCellStyle(row, column, rowIndex, columnIndex) {
      //根据报警级别显示颜色
      // return 'background:SlateGray ;color:white'
      return "background:#4c7cfc ;color:white";
    },
    // 表格颜色
    cellStyle(row, column, rowIndex, columnIndex) {
      //根据报警级别显示颜色
      // console.log(row);
      // console.log(row.column);
      if (row.column.label === "重大风险统计") {
        return "color:red";
      } else if (row.column.label === "操作") {
        return "color:#3090f0;cursor:pointer;";
      } else if (
        row.column.label === "风险等级" &&
        row.row.riskrank === "低风险"
      ) {
        return "color:blue";
      } else if (
        row.column.label === "风险等级" &&
        row.row.riskrank === "一般风险"
      ) {
        return "color:skyblue";
      } else if (
        row.column.label === "风险等级" &&
        row.row.riskrank === "较大风险"
      ) {
        return "color:orange";
      } else if (
        row.column.label === "风险等级" &&
        row.row.riskrank === "重大风险"
      ) {
        return "color:red";
      }
    },

    // 切换每页显示几条数据
    handleSizeChange(val) {
      this.pageSize = val;
      // console.log(this.pageSize);
      if (this.current == 1) {
        // 第一个卡片分页获取
        this.getProjectRiskStatistical();
      }
    },
    // 切换页码
    handleCurrentChange(val) {
      this.page = val;
      if (this.current == 1) {
        // 第一个卡片分页获取
        this.getProjectRiskStatistical();
      }
    },
    // 点击项目详情
    async getPorjectRisk(row) {
      // console.log(row);
      this.page = 1;
      this.pageSize = 10;
      this.current = 2;
      this.projectInfo.name = row.name;
      this.projectInfo.create_time = row.create_time;
      this.projectInfo.update_time = row.update_time;
      this.projectInfo.engineering = row.engineering;
      this.projectInfo.angineering = row.angineering;
      this.getPictureInfo(row);
    },

    // 搜索某个项目下的风险
    searhProjectBtn() {
      this.page = 1;
      this.pageSize = 10;

      this.getSecondRisk();
    },

    // 从第二个卡片返回第一个卡片
    goProject() {
      this.current = 1;
      this.page = 1;
      this.pageSize = 10;
      this.getProjectRiskStatistical();
      this.scoreFormData.builder_license.length = 0;
      this.scoreFormData.safety_license.length = 0;
      this.scoreFormData.enterprise_level.length = 0;
      this.scoreFormData.honor_license.length = 0;
      this.scoreFormData.Complete_certify.length = 0;
    },
  },
};
</script>

<style lang="less" scoped>
.container {
  width: 100%;
  height: 100%;

  .statistical {
    width: 100%;
    height: 150px;
    display: flex;
    padding-top: 20px;
    justify-content: space-between;

    //   background-color: pink;
    .left {
      display: flex;

      .riskBg {
        width: 160px;
        height: 105px;
        background-color: skyblue;
        background: url("../../../assets/img/risk1.png") no-repeat;
        padding: 20px 0 0 20px;
        margin-right: 20px;
        border-radius: 5px;

        .riskTitle {
          font-size: 14px;
          color: #fff;
          margin-bottom: 10px;
        }

        .riskNum {
          font-size: 22px;
          color: #fff;
        }
      }

      > :nth-child(2) {
        background: url("../../../assets/img/risk2.png") no-repeat;
      }

      > :nth-child(3) {
        background: url("../../../assets/img/risk2.png") no-repeat;
      }

      > :nth-child(4) {
        background: url("../../../assets/img/risk3.png") no-repeat;
      }

      > :nth-child(5) {
        background: url("../../../assets/img/risk4.png") no-repeat;
      }
    }

    .right {
      padding-top: 30px;
    }
  }

  .projectInfo {
    width: 100%;
    height: 200px;
    border: 1px solid #d9d9d9;

    margin-bottom: 20px;

    div {
      width: 100%;
      height: 30px;
      padding: 0 20px;
      line-height: 30px;

      span {
        padding-left: 10px;
      }
    }

    .projectTitle {
      width: 100%;
      height: 50px;
      color: #4d7cfe;
      display: flex;
      justify-content: space-between;
      align-items: center;
      // padding: 0 20px;
      border-bottom: 1px solid #d9d9d9;
    }
  }

  .projectSetps {
    width: 100%;
    height: 160px;
    padding: 0 20px;
    border: 1px solid #e8e8e8;
    margin-bottom: 20px;

    .setpsTitle {
      width: 100%;
      height: 50px;
      color: #4d7cfe;
      display: flex;
      justify-content: space-between;
      align-items: center;
      // padding: 0 20px;
      border-bottom: 1px solid #e8e8e8;
    }

    .setpsBody {
      padding-top: 20px;
    }
  }

  .search {
    width: 100%;
    height: 80px;

    // background-color: brown;
    .el-col {
      padding: 0 20px;
    }

    .searhBtn {
      margin-left: 20px;
    }
  }

  .main {
    width: 100%;
    // height: 400px;
    // background-color: cornflowerblue;
  }

  .out {
    width: 100%;
    height: 850px;
    background-color: pink;
  }

  .main /deep/ .el-table td,
  .el-table th {
    border-bottom: 1px solid #fff;
  }

  .situation {
    width: 100%;
    // padding: 0 20px;
    border: 1px solid #d9d9d9;

    .situationTitle {
      width: 100%;
      height: 50px;
      color: #4d7cfe;
      padding-left: 40px;
      line-height: 50px;
      border-bottom: 1px solid #d9d9d9;
    }

    .situationBody {
      width: 100%;
      padding: 0 20px;

      .prove {
        height: 40px;
        line-height: 40px;
      }

      .imgShow {
        width: 100%;
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;

        .imgList {
          width: 45%;
          height: 230px;
          border: 1px solid #d9d9d9;
          display: flex;
          flex-direction: column;
          margin: 10px;

          .img_top {
            flex: 3;
            display: flex;
            align-items: center;
            padding: 0 20px;
            // background-color: pink;
          }

          .img_bottom {
            flex: 1;
            // background-color: blueviolet;
            display: flex;
            align-items: center;
            padding-left: 20px;
          }
        }
      }

      .completion {
        width: 100%;
        height: 200px;
        border: 1px solid #d9d9d9;
        padding: 50px 20px;
      }
    }

    .el-image {
      margin-right: 30px;
    }
  }

  //   .el-table__row>td{
  // 	border: none;
  // }
  // .el-table::before {//去掉最下面的那一条线
  // 	height: 0px;
  // }
}
</style>